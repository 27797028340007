import { Button, Space, Table, Tabs, Tag } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../api";
import dayjs from "dayjs";
import { FileOutlined, FileTextOutlined } from "@ant-design/icons";
import { downloadFile, withTooltip } from "../util";

const columns = [
    {
        key: "timestamp",
        dataIndex: "ts",
        title: "Data",
        ellipsis: true,
        width: 200,
        render: (x) => dayjs(x).format("DD/MM/YYYY HH:mm:ss"),
    },
    {
        key: "sender",
        dataIndex: "sentBy",
        title: "Mittente",
        width: 200,
        ellipsis: true,
        render: (x) => x.fullName,
    },
    {
        key: "accounting",
        dataIndex: "accounting",
        title: "Foglio n.",
        width: 200,
        ellipsis: true,
        render: x => `${x.id} - ${x.name}`
    },
    {
        key: "to",
        dataIndex: "sentTo",
        title: "Destinatario",
        width: 200,
        ellipsis: true,
        render: (x) => x.fullName,
    },
    {
        key: "cc",
        dataIndex: "cc",
        title: "CC",
        width: 200,
        ellipsis: true,
        render: (x) => x.map((y) => y.fullName).join(", "),
    },
    {
        key: "sent",
        dataIndex: "sent",
        title: "Esito",
        width: 100,
        render: (x) => (x ? <Tag color="green">OK</Tag> : <Tag color="red">Fallito</Tag>),
    },
    {
        key: "attachments",
        dataIndex: "attachments",
        title: "Allegati",
        width: 200,
        render: (attachments) => (
            <Space wrap>
                {attachments.map((x) =>
                    withTooltip(
                        <Button
                            size="small"
                            type="text"
                            icon={<FileTextOutlined />}
                            onClick={() => downloadFile(`emailHistory/attachment/${x.id}`)}
                        />,
                        x.filename,
                    ),
                )}
            </Space>
        ),
    },
];

export default function OrderAccountingEmailHistory() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { orderId } = useParams();

    useEffect(() => {
        setLoading(true);
        axiosInstance
            .get("emailHistory", { params: { orderId: orderId } })
            .then(({ data }) => setData(data.sort((a, b) => dayjs(b.ts).diff(dayjs(a.ts)))))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={data.map((x) => ({ ...x, key: x.id }))}
            tableLayout="fixed"
            loading={loading}
            scroll={{ x: columns.reduce((acc, val) => acc + val.width, 0) }}
        />
    );
}
