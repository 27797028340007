import { Button, Card, Checkbox, DatePicker, Form, InputNumber, Select, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import dayjs from "dayjs";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadFile } from "../util";
import BackTitle from "../common/BackTitle";
import { UserContext } from "../App";
const { RangePicker } = DatePicker;

export default function Functions() {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deltaLoading, setDeltaLoading] = useState(false);
    const user = useContext(UserContext);

    useEffect(() => {
        axiosInstance.get("customer/compact").then(({ data }) => setCustomers(data));
    }, []);

    const onFinish = (form) => {
        const startDate = form.range[0].startOf("month").format("YYYY-MM-DD");
        const endDate = form.range[1].endOf("month").format("YYYY-MM-DD");
        const params = {
            unpaidOnly: false,
            customerId: form.customer,
            startDate,
            excel: true,
            endDate,
        };
        setLoading(true);
        downloadFile("accountingItem", "get", null, { params }).finally(() => setLoading(false));
        //axiosInstance.get('accountingItem', {params})
        //    .then(({ data }) => console.log(data.data))
        //    .finally(() => setLoading(false))
    };
    const tabs = [
        {
            key: "prod",
            label: "Produzione",
            permission: "accountingItem.read",
            children: (
                <>
                    <h3 style={{ marginTop: 0, marginBottom: 20 }}>Report di produzione</h3>
                    <Form onFinish={onFinish} layout="vertical">
                        <Form.Item label={"Cliente"} name={"customer"}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                placeholder="Seleziona il cliente"
                                options={customers.map((x) => ({ label: x.name, value: x.id }))}
                            />
                        </Form.Item>
                        <Form.Item label={"Competenza Da/a"} name={"range"} rules={[requiredField]}>
                            <RangePicker picker="month" format="MMMM YYYY" />
                        </Form.Item>
                        <Form.Item style={{ textAlign: "end" }}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<DownloadOutlined />}
                                loading={loading}
                            >
                                Scarica
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            ),
        },
        {
            key: "delta",
            label: "Delta",
            permission: "orderDelta.read",
            children: (
                <>
                    <h3 style={{ marginTop: 0, marginBottom: 20 }}>Report Delta commesse</h3>
                    <Form
                        title="Estratto conto cliente"
                        layout="vertical"
                        onFinish={(formData) => {
                            setDeltaLoading(true);
                            const fields = [
                                { k: "customer", v: formData.customer },
                                {
                                    k: "startDate",
                                    v: formData.range[0].startOf("month").format("YYYY-MM-DD"),
                                },
                                {
                                    k: "endDate",
                                    v: formData.range[1].endOf("month").format("YYYY-MM-DD"),
                                },
                                formData.thresholdFactor && {
                                    k: "thresholdFactor",
                                    v: formData.thresholdFactor / 100,
                                },
                                { k: "includePositiveDelta", v: formData.includePositiveDelta },
                            ].filter((x) => !!x);

                            downloadFile(
                                `/delta/statement?${fields.map((x) => x.k + "=" + x.v).join("&")}`,
                            ).finally(() => setDeltaLoading(false));
                        }}
                    >
                        <Form.Item label={"Cliente"} name={"customer"} rules={[requiredField]}>
                            <Select
                                showSearch
                                filterOption={filterOption}
                                placeholder="Seleziona il cliente"
                                options={customers.map((x) => ({ label: x.name, value: x.id }))}
                            />
                        </Form.Item>

                        <Form.Item label={"Competenza Da/a"} name={"range"} rules={[requiredField]}>
                            <RangePicker picker="month" format="MMMM YYYY" />
                        </Form.Item>

                        <Form.Item label="Tolleranza" name="thresholdFactor">
                            <InputNumber suffix="%" />
                        </Form.Item>
                        <Form.Item
                            label="Altri filtri"
                            name="includePositiveDelta"
                            valuePropName="checked"
                        >
                            <Checkbox>Includi delta positivi</Checkbox>
                        </Form.Item>
                        <Form.Item style={{ textAlign: "end" }}>
                            <Button
                                htmlType="submit"
                                type="primary"
                                icon={<DownloadOutlined />}
                                loading={deltaLoading}
                            >
                                Scarica
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            ),
        },
    ].filter((x) => user.permissions?.includes(x.permission));
    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <BackTitle backEnabled={false} title="Report" padding={0} />
            <Tabs tabPosition="left" items={tabs} animated={false} />
        </div>
    );
}
