import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { Card, Col, Input, List, message, Row } from "antd";
import ContactlessSvg from "./contactless.svg";
import "./KioskPage.css";

const CARD_FONT_SIZE = 20;

const Clock = () => {
    const [date, setDate] = useState(dayjs());

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);
        return () => {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setDate(dayjs());
    };

    return <div>{date.format("dddd DD/MM/YYYY HH:mm").toUpperCase()}</div>;
};

const renderAllocationItem = (item) => (
    <Card
        title={<span style={{ fontSize: 30,fontWeight: 600 }}>{item.order.name}</span>}
        extra={<span style={{ fontSize: 30,fontWeight: 600 }}>{item.order.customer}</span>}
        style={{ margin: 8, fontSize: 30, fontWeight: 900 }}
    >
        <table style={{lineHeight: 1.2, textTransform:'uppercase'}}>
            <tr>
                <td className="label">Indirizzo:</td>
                <td>{item.order.fullAddress}</td>
            </tr>
            <tr>
                <td className="label">Responsabile:</td>
                <td>{item.manager.fullName}</td>
            </tr>

            <tr>
                <td className="label">Indicazioni:</td>
                <td>{item.notes}</td>
            </tr>

            <tr style={{color: 'blue'}}>
                <td className="label">Persone:</td>
                <td>{item.employees.map((x) => x.fullName).join(" - ")}</td>
            </tr>
            <tr style={{color: 'brown'}}>
                <td className="label">Mezzi:</td>
                <td>{item.vehicles.map((x) => x.label + " " + x.name).join(" - ")}</td>
            </tr>
        </table>
    </Card>
);

export default function () {
    const { kioskId } = useParams();
    const [timer, setTimer] = useState();
    const [rfidValue, setRfidValue] = useState();
    const [inputValue, setInputValue] = useState();
    const [data, setData] = useState({});
    const [displayTimer, setDisplayTimer] = useState();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const displayTime = searchParams.get("displayTime") ?? 60000;
    const ref = useRef();

    useEffect(() => {
        if (ref.current) ref.current.focus();
    }, [ref.current]);

    const handleTextChange = (text) => {
        setInputValue(text);
        if (timer) clearTimeout(timer);
        const timeout = setTimeout(() => {
            setRfidValue(Number(`0x${text.substring(2)}`));
            setInputValue();
        }, 250);
        setTimer(timeout);
    };

    useEffect(() => {
        if (rfidValue) {
            setLoading(true);
            clearTimeout(displayTimer);
            axios
                .get(`${process.env.REACT_APP_API_BASE_URL}/kiosk/${kioskId}`, {
                    params: {
                        date: dayjs().format("YYYY-MM-DD"),
                        badgeId: rfidValue,
                    },
                })
                .then(({ data }) => {
                    setData(data);
                    setDisplayTimer(
                        setTimeout(() => {
                            setRfidValue();
                            setData({});
                        }, displayTime),
                    );
                })
                .catch(() => {
                    message.error("Errore di rete");
                    setData({});
                    setRfidValue();
                })
                .finally(() => setLoading(false));
        }
    }, [rfidValue]);

    useEffect(() => {
        document.addEventListener("click", () => ref.current.focus());
    }, []);

    return (
        <div style={{ height: "100%", width: "100%" }}>
            <Input
                ref={ref}
                value={inputValue}
                style={{ opacity: 0 }}
                onChange={({ target }) => handleTextChange(target.value)}
            />
            {!rfidValue ? (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        background: "#d9d9d9",
                        alignItems: "center",
                        fontSize: 50,
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <img src={ContactlessSvg} height={300} />
                        <div>
                            <b>AVVICINARE IL BADGE AL LETTORE</b>
                        </div>
                    </div>
                </div>
            ) : (
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        background: "#d9d9d9",
                    }}
                >
                    <List
                        dataSource={data.allocations ?? []}
                        loading={loading}
                        grid={{ column: 2}}
                        renderItem={(item) => <List.Item>{renderAllocationItem(item)}</List.Item>}
                    />
                </div>
            )}
            <Row
                style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "black",
                    color: "white",
                    padding: 16,
                    zIndex: 2,
                }}
            >
                <Col span={12} style={{ fontSize: 30 }}>
                    {data.employee && (
                        <>
                            UTENTE: <b>{data.employee.fullName.toUpperCase()}</b>
                        </>
                    )}
                </Col>
                <Col span={12} style={{ fontSize: 30, fontWeight: "bold" }}>
                    <div style={{ fontVariantNumeric: "tabular-nums", textAlign: "end" }}>
                        <Clock />
                    </div>
                </Col>
            </Row>
        </div>
    );
}
