import { Card, Col, DatePicker, Form, Input, List, Modal, Row, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField, withTooltip } from "../util";
import dayjs from "dayjs";
import { UserContext } from "../App";

const isDuplicate = (a, b) => {
    const compArray = (as, bs) => {
        const setA = new Set(as);
        const setB = new Set(bs);
        return setA.size === setB.size && setA.intersection(setB).size === setA.size;
    };
    const sameEmployees = compArray(
        a.employees.map((x) => x.id),
        b.employees.map((x) => x.id),
    );
    const sameVehicles = compArray(
        a.vehicles.map((x) => x.id),
        b.vehicles.map((x) => x.id),
    );
    const sameDescription = a.notes === b.notes;
    return sameEmployees && sameVehicles && sameDescription;
};

const dropDuplicates = (allocations) =>
    allocations.filter(
        (item, index) => allocations.findIndex((el) => isDuplicate(item, el)) === index,
    );

const renderSuggestion = (item, form) => (
    <Card
        bodyStyle={{ padding: 0 }}
        hoverable
        style={{ margin: 16, minWidth: 250 }}
        onClick={() => {
            const formValues = {
                order: item.order.id,
                employees: item.employees.map((x) => x.id),
                vehicles: item.vehicles.map((x) => x.id),
                notes: item.notes,
            };
            form.setFieldsValue(formValues);
        }}
    >
        <div style={{ textAlign: "center" }}>
            <div>
                <div style={{ background: "#1677ff", color: "white" }}>
                    <b>Indicazioni alla squadra</b>
                </div>
                <div
                    style={{
                        background: "#e6f4ff",
                        height: 70,
                        textAlign: "start",
                        padding: 5,
                    }}
                >
                    {item.notes}
                </div>
            </div>
        </div>

        <Row>
            <Col span={12}>
                <div style={{ background: "#237804", color: "white" }}>
                    <b>Personale</b>
                </div>
                <div style={{ background: "#f6ffed", overflowY: "auto", height: 100 }}>
                    <List
                        dataSource={item.employees}
                        renderItem={(emp) => (
                            <List.Item
                                style={{
                                    padding: 2,
                                }}
                            >
                                <Typography.Text ellipsis style={{ fontSize: 11 }}>
                                    {withTooltip(emp.fullName)}
                                </Typography.Text>
                            </List.Item>
                        )}
                    />
                </div>
            </Col>
            <Col span={12}>
                <div style={{ background: "#ad6800", color: "white" }}>
                    <b>Mezzi</b>
                </div>
                <div style={{ background: "#fffbe6", overflowY: "auto", height: 100 }}>
                    <List
                        dataSource={item.vehicles}
                        renderItem={(v) => (
                            <List.Item
                                style={{
                                    padding: 2,
                                }}
                            >
                                <Typography.Text ellipsis style={{ fontSize: 11 }}>
                                    {withTooltip(v.label + " - " + v.name)}
                                </Typography.Text>
                            </List.Item>
                        )}
                    />
                </div>
            </Col>
        </Row>
    </Card>
);

export default function ({ open, onOk, onCancel, date }) {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [users, setUsers] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [form] = Form.useForm();
    const orderId = Form.useWatch("order", form);
    const order = orders.filter((x) => x.id === orderId)?.[0];
    const [suggestions, setSuggestions] = useState([]);
    const [loadingSuggestion, setLoadingSuggestions] = useState(false);
    const user = useContext(UserContext)
    const selectedOrder = Form.useWatch("order", form);
    console.log(user)

    useEffect(() => {
        if (open) {
            form.setFieldsValue({ date: dayjs(date), manager: user.id });
            axiosInstance
                .get("order", { params: { pageSize: 99999 } })
                .then(({ data }) => setOrders(data.data));
            axiosInstance
                .get("employee", { params: { date: date } })
                .then(({ data }) =>
                    setEmployees(data.map((x) => ({ value: x.id, label: x.fullName }))),
                );
            axiosInstance
                .get("vehicle", { params: { date: date } })
                .then(({ data }) =>
                    setVehicles(data.map((x) => ({ value: x.id, label: x.label + " " + x.name }))),
                );

            axiosInstance.get("user").then(({ data }) => setUsers(data));
        }
    }, [open]);

    useEffect(() => {
        if (selectedOrder) {
            setLoadingSuggestions(true);
            axiosInstance
                .get("allocation", { params: { order: selectedOrder } })
                .then(({ data }) => setSuggestions(dropDuplicates(data).slice(0, 5)))
                .finally(() => setLoadingSuggestions(false));
        }
    }, [selectedOrder]);

    const onFinish = (data) => {
        setLoading(true);
        data.date = data.date.format("YYYY-MM-DD");
        axiosInstance
            .post("allocation", data)
            .then(() => {
                onOk?.();
                form.resetFields();
                setSuggestions([]);
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title="Nuova assegnazione"
            open={open}
            width={1000}
            onCancel={() => {
                onCancel();
                form.resetFields();
                setSuggestions([]);
            }}
            okButtonProps={{
                htmlType: "submit",
                form: "newResourceAllocationForm",
                loading: loading,
            }}
        >
            <Form id="newResourceAllocationForm" layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item label={"Data"} name={"date"} rules={[requiredField]}>
                    <DatePicker style={{ width: "100%" }} format={"dddd DD/MM/YYYY"} disabled />
                </Form.Item>
                <Form.Item name={"manager"} label="Responsabile" rules={[requiredField]}>
                    <Select
                        options={users.map((x) => ({ label: x.fullName, value: x.id }))}
                        filterOption={filterOption}
                        showSearch
                    />
                </Form.Item>
                <Form.Item label="Commessa" name={"order"} rules={[requiredField]}>
                    <Select
                        options={orders.map((x) => ({ value: x.id, label: x.title }))}
                        showSearch
                        disabled={loadingSuggestion}
                        filterOption={filterOption}
                    />
                </Form.Item>
                {order && (
                    <Typography>
                        <pre>
                            {order.customer.name}
                            <br />
                            {order.fullAddress}
                        </pre>
                    </Typography>
                )}
                {suggestions.length > 0 && (
                    <div>
                        <div
                            style={{
                                background: "gray",
                                color: "white",
                                fontWeight: "bold",
                                textAlign: "center",
                            }}
                        >
                            Suggerimenti (clicca per autocompletamento)
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10",
                                overflowX: "auto",
                                background: "#fafafa",
                                border: "1px solid rgba(140, 140, 140, 0.35)",
                            }}
                        >
                            {suggestions.map((item) => renderSuggestion(item, form))}
                        </div>
                    </div>
                )}
                <Form.Item label="Personale" name={"employees"} rules={[requiredField]}>
                    <Select
                        options={employees}
                        showSearch
                        filterOption={filterOption}
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item label="Mezzi" name={"vehicles"}>
                    <Select
                        options={vehicles}
                        showSearch
                        filterOption={filterOption}
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item label="Indicazioni per la squadra" name={"notes"}>
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
}
