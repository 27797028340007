import { Form, Input, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../../api";
import Toolbar from "../../common/Toolbar";

const NewEmployeeMansion = ({ open, onOk, onCancel }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (open) form.resetFields();
    }, [open]);

    const onFinish = (formData) => {
        setLoading(true);
        axiosInstance
            .post("employeeMansion", formData)
            .then(() => {
                onOk?.();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            title="Nuova mansione"
            onCancel={onCancel}
            okButtonProps={{ htmlType: "submit", form: "NewEmployeeMansionForm", loading }}
        >
            <Form form={form} id="NewEmployeeMansionForm" onFinish={onFinish}>
                <Form.Item label="Id" name="id">
                    <Input />
                </Form.Item>
                <Form.Item label="Nome" name="name">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default function () {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newOpen, setNewOpen] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get("employeeMansion")
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const deleteRecord = (ids) =>
        axiosInstance.delete(`employeeMansion/${ids[0]}`).then(fetchData);

    return (
        <>
            <NewEmployeeMansion
                open={newOpen}
                onOk={() => {
                    setNewOpen(false);
                    fetchData();
                }}
                onCancel={() => setNewOpen(false)}
            />
            <Toolbar
                selectedKeys={selectedRowKeys}
                addAction={() => setNewOpen(true)}
                deleteAction={deleteRecord}
            />
            <Table
                tableLayout="fixed"
                dataSource={data}
                size="small"
                columns={[
                    {
                        key: "id",
                        dataIndex: "id",
                        title: "ID",
                    },
                    {
                        key: "name",
                        dataIndex: "name",
                        title: "Nome",
                    },
                ]}
                rowKey="id"
                rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys, type: "radio" }}
            />
        </>
    );
}
