import {
    DeleteOutlined,
    EditOutlined,
    FilePdfOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined,
    LeftOutlined,
    PlusOutlined,
    RightOutlined,
    SearchOutlined,
    WarningOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Col,
    DatePicker,
    Input,
    List,
    Popconfirm,
    Row,
    Space,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import BackTitle from "../common/BackTitle";
import { downloadFile, withTooltip } from "../util";
import EditResourceAllocation from "./EditResourceAllocation";
import NewResourceAllocation from "./NewResourceAllocation";
import dayjs from "dayjs";
import { useParams, useSearchParams } from "react-router-dom";
import PermissionRequired from "../common/PermissionRequired";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

const renderEmployee = (data, unavailableEmployees, item, emp) => {
    const isUnavailable = unavailableEmployees.filter((x) => x.id === emp.id).length > 0;
    const isAlreadyAssigned = data.some(
        (x) => x.id !== item.id && x.employees.some((y) => y.id === emp.id),
    );

    return (
        <>
            {isAlreadyAssigned && <WarningOutlined />}
            <Typography.Text
                type={isUnavailable && "danger"}
                ellipsis
                strong={isUnavailable}
                style={{ fontSize: 11 }}
            >
                {withTooltip(emp.fullName)}
            </Typography.Text>
        </>
    );
};

const renderResAllocItem = (
    readOnly,
    isFullScreen,
    data,
    unavailableEmployees,
    item,
    onEdit,
    onDelete,
) => (
    <List.Item>
        <Card
            bodyStyle={{ padding: 0 }}
            actions={
                !readOnly &&
                !isFullScreen && [
                    <PermissionRequired name="resourceAllocation.update">
                        <Button icon={<EditOutlined />} onClick={onEdit} type="text">
                            Modifica
                        </Button>
                    </PermissionRequired>,
                    <PermissionRequired name={"resourceAllocation.delete"}>
                        <Popconfirm
                            title="Elimina assegnazione"
                            description="Procedere?"
                            onConfirm={onDelete}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button icon={<DeleteOutlined />} type="text" danger>
                                Elimina
                            </Button>
                        </Popconfirm>
                    </PermissionRequired>,
                ]
            }
        >
            <div style={{ textAlign: "center" }}>
                <div>
                    <div style={{ background: "#595959", color: "white" }}>
                        <b>COMMESSA</b>
                    </div>
                    <div style={{ background: "#f0f0f0", lineHeight: 1 }}>
                        <Typography.Text ellipsis>{withTooltip(item.order.name)}</Typography.Text>
                        <br />
                        <Typography.Text ellipsis style={{ fontSize: 10 }}>
                            {withTooltip(item.order.customer)}
                        </Typography.Text>
                        <br />
                        <Typography.Text ellipsis style={{ fontSize: 10 }}>
                            {withTooltip(item.order.fullAddress)}
                        </Typography.Text>
                    </div>
                </div>

                <div>
                    <div style={{ background: "#531dab", color: "white" }}>
                        <b>Responsabile</b>
                    </div>
                    <div
                        style={{
                            background: "#f9f0ff",
                            textAlign: "center",
                            padding: 5,
                        }}
                    >
                        {item.manager.fullName}
                    </div>
                </div>
                <div>
                    <div style={{ background: "#1677ff", color: "white" }}>
                        <b>Indicazioni alla squadra</b>
                    </div>
                    <div
                        style={{
                            background: "#e6f4ff",
                            minHeight: 70,
                            textAlign: "start",
                            padding: 5,
                        }}
                    >
                        {item.notes}
                    </div>
                </div>
            </div>

            <Row>
                <Col span={12}>
                    <div style={{ background: "#237804", color: "white" }}>
                        <b>Personale</b>
                    </div>
                    <div style={{ background: "#f6ffed", overflowY: "auto", height: 100 }}>
                        <List
                            dataSource={item.employees}
                            renderItem={(emp) => (
                                <List.Item
                                    style={{
                                        padding: 2,
                                    }}
                                >
                                    {renderEmployee(data, unavailableEmployees, item, emp)}
                                </List.Item>
                            )}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div style={{ background: "#ad6800", color: "white" }}>
                        <b>Mezzi</b>
                    </div>
                    <div style={{ background: "#fffbe6", overflowY: "auto", height: 100 }}>
                        <List
                            dataSource={item.vehicles}
                            renderItem={(v) => (
                                <List.Item
                                    style={{
                                        padding: 2,
                                    }}
                                >
                                    {data.some(
                                        (x) =>
                                            x.id !== item.id &&
                                            x.vehicles.some((y) => y.id === v.id),
                                    ) && <WarningOutlined />}
                                    <Typography.Text ellipsis style={{ fontSize: 11 }}>
                                        {withTooltip(v.label + " - " + v.name)}
                                    </Typography.Text>
                                </List.Item>
                            )}
                        />
                    </div>
                </Col>
            </Row>
        </Card>
    </List.Item>
);
const includes = (a, b) => a.toLowerCase().includes(b.toLowerCase());

export default function ResourceAllocation() {
    const [newAllocOpen, setNewAllocOpen] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [selectedResAlloc, setSelectedResAlloc] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [keyword, setKeyword] = useState();
    const date = searchParams.get("date") ?? dayjs().format("YYYY-MM-DD");
    const dateObj = dayjs(date);
    const unavailableEmployees = employees.filter((x) => !x.availableAtWork);
    const fullScreenHandle = useFullScreenHandle();

    const readOnly = dayjs().startOf("day").diff(dateObj, "day") >= 2;

    const fetchData = () => {
        setLoading(true);
        axiosInstance.get("employee", { params: { date } }).then(({ data }) => setEmployees(data));

        axiosInstance.get("vehicle", { params: { date } }).then(({ data }) => setVehicles(data));

        axiosInstance
            .get("allocation", { params: { date } })
            .then(({ data }) => setData(data))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [date]);

    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
            <BackTitle backEnabled={false} title="Programmazione risorse" padding={0} />
            <EditResourceAllocation
                data={selectedResAlloc}
                onOk={() => {
                    setSelectedResAlloc();
                    fetchData();
                }}
                onCancel={() => setSelectedResAlloc()}
            />
            <NewResourceAllocation
                open={newAllocOpen}
                date={date}
                onOk={() => {
                    setNewAllocOpen(false);
                    fetchData();
                }}
                onCancel={() => setNewAllocOpen(false)}
            />
            <Row style={{ alignItems: "center" }}>
                <Col flex={"none"}>
                    <Button
                        type="text"
                        size="large"
                        onClick={() => {
                            searchParams.set("date", dateObj.add(-1, "day").format("YYYY-MM-DD"));
                            setSearchParams(searchParams);
                        }}
                    >
                        <LeftOutlined />
                        {dateObj.add(-1, "day").format("DD/MM/YYYY")}
                    </Button>
                </Col>
                <Col flex={"auto"} style={{ textAlign: "center", lineHeight: 2 }}>
                    Data visualizzata
                    <br />
                    <DatePicker
                        value={dateObj}
                        format="dddd DD/MM/YYYY"
                        allowClear={false}
                        onChange={(newDate) => {
                            searchParams.set("date", newDate.format("YYYY-MM-DD"));
                            setSearchParams(searchParams);
                        }}
                    />
                </Col>
                <Col flex={"none"}>
                    <Button
                        type="text"
                        size="large"
                        onClick={() => {
                            searchParams.set("date", dateObj.add(1, "day").format("YYYY-MM-DD"));
                            setSearchParams(searchParams);
                        }}
                    >
                        {dateObj.add(1, "day").format("DD/MM/YYYY")}
                        <RightOutlined />
                    </Button>
                </Col>
            </Row>
            <div style={{ width: "100%", textAlign: "end" }}>
                {!readOnly && (
                    <PermissionRequired name="resourceAllocation.create">
                        <Button
                            type="primary"
                            onClick={() => setNewAllocOpen(true)}
                            icon={<PlusOutlined />}
                        >
                            Nuova Assegnazione
                        </Button>
                    </PermissionRequired>
                )}
            </div>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={10}>
                    <Row>
                        <Col span={8}>
                            <div style={{ background: "#237804", color: "white" }}>
                                <b>Personale</b>
                            </div>
                            <div
                                style={{ background: "#f6ffed", overflowY: "auto", maxHeight: 600 }}
                            >
                                <List
                                    dataSource={employees
                                        .filter((x) => x.availableAtWork)
                                        .filter(
                                            (x) =>
                                                !data.some((y) =>
                                                    y.employees.some((z) => z.id === x.id),
                                                ),
                                        )
                                        .sort((a, b) => a.fullName.localeCompare(b.fullName))}
                                    renderItem={(item) => (
                                        <List.Item style={{ padding: 2 }}>
                                            <Typography.Text ellipsis>
                                                {withTooltip(item.fullName)}
                                            </Typography.Text>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{ background: "#f5222d", color: "white" }}>
                                <b>Assenti</b>
                            </div>
                            <div
                                style={{
                                    background: "#fff1f0",
                                    overflowY: "auto",
                                    maxHeight: 600,
                                }}
                            >
                                <List
                                    dataSource={employees
                                        .filter((x) => !x.availableAtWork)
                                        .sort((a, b) => a.fullName.localeCompare(b.fullName))}
                                    renderItem={(item) => (
                                        <List.Item style={{ padding: 2 }}>
                                            <Typography.Text ellipsis>
                                                {withTooltip(item.fullName)}
                                            </Typography.Text>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div style={{ background: "#ad6800", color: "white" }}>
                                <b>Mezzi</b>
                            </div>
                            <div
                                style={{ background: "#fffbe6", overflowY: "auto", maxHeight: 600 }}
                            >
                                <List
                                    dataSource={vehicles.filter(
                                        (x) =>
                                            !data.some((y) =>
                                                y.vehicles.some((z) => z.id === x.id),
                                            ),
                                    )}
                                    renderItem={(item) => (
                                        <List.Item style={{ padding: 2 }}>
                                            <Typography.Text ellipsis>
                                                {withTooltip(item.label + " - " + item.name)}
                                            </Typography.Text>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={14}>
                    <FullScreen handle={fullScreenHandle}>
                        <div
                            style={{
                                height: fullScreenHandle.active ? "100vh" : 650,
                                overflow: "auto",
                                background: "#d9d9d9",
                                border: "1px solid rgba(140, 140, 140, 0.35)",
                            }}
                        >
                            <div
                                style={{
                                    position: "sticky",
                                    background: "white",
                                    padding: 10,
                                    boxShadow: "0 2px 6px -4px gray",
                                    zIndex: 1,
                                    top: 0,
                                }}
                            >
                                <Row>
                                    <Col flex={"auto"}>
                                        <Space>
                                            <Input
                                                placeholder="Parola chiave"
                                                allowClear
                                                suffix={<SearchOutlined />}
                                                onChange={({ target }) => setKeyword(target.value)}
                                                value={keyword}
                                                style={{
                                                    width: 250,
                                                }}
                                            />

                                            <Button
                                                onClick={() =>
                                                    downloadFile(`allocation/pdf?date=${date}`)
                                                }
                                                icon={<FilePdfOutlined />}
                                            >
                                                Stampa PDF
                                            </Button>
                                        </Space>
                                    </Col>
                                    <Col flex={"none"}>
                                        <Button
                                            icon={
                                                fullScreenHandle.active ? (
                                                    <FullscreenExitOutlined />
                                                ) : (
                                                    <FullscreenOutlined />
                                                )
                                            }
                                            onClick={() =>
                                                fullScreenHandle.active
                                                    ? fullScreenHandle.exit()
                                                    : fullScreenHandle.enter()
                                            }
                                        >
                                            {fullScreenHandle.active ? "Chiudi" : "Apri"} Schermata
                                            intera
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ padding: 16 }}>
                                <List
                                    key={"allocations_" + fullScreenHandle.active}
                                    loading={loading}
                                    grid={{
                                        gutter: 16,
                                        xs: fullScreenHandle.active ? 1 : 1,
                                        sm: fullScreenHandle.active ? 2 : 1,
                                        md: fullScreenHandle.active ? 3 : 1,
                                        lg: fullScreenHandle.active ? 4 : 2,
                                        xl: fullScreenHandle.active ? 4 : 2,
                                        xxl: fullScreenHandle.active ? 8 : 3,
                                    }}
                                    dataSource={data
                                        .sort((a, b) => b.id - a.id)
                                        .filter(
                                            (x) =>
                                                !keyword ||
                                                x.employees.some((z) =>
                                                    includes(z.fullName, keyword),
                                                ) ||
                                                x.vehicles.some((z) =>
                                                    includes(z.label + z.name, keyword),
                                                ) ||
                                                includes(
                                                    x.order.name +
                                                        x.order.fullAddress +
                                                        x.order.customer +
                                                        x.notes,
                                                    keyword,
                                                ) ||
                                                includes(x.manager.fullName, keyword),
                                        )}
                                    renderItem={(item) =>
                                        renderResAllocItem(
                                            readOnly,
                                            fullScreenHandle.active,
                                            data,
                                            unavailableEmployees,
                                            item,
                                            () => setSelectedResAlloc(item),
                                            () =>
                                                axiosInstance
                                                    .delete(`allocation/${item.id}`)
                                                    .then(() => fetchData()),
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </FullScreen>
                </Col>
            </Row>
        </div>
    );
}
