import { Button, Checkbox, Col, DatePicker, Form, Row, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

const columns = (includeMonth) =>
    [
        includeMonth && {
            key: "month",
            dataIndex: "month",
            ellipsis: true,
            width: 150,
            title: "Competenza",
            render: (x) => dayjs(x).format("YYYY/MM"),
            sorter: (a,b) => dayjs(a.month).diff(b.month)
        },
        {
            key: "refId",
            dataIndex: "refId",
            ellipsis: true,
            width: 150,
            title: "Voce",
            sorter: (a,b) => a.refId.localeCompare(b.refId)
        },
        {
            key: "description",
            dataIndex: "description",
            width: 300,
            ellipsis: true,
            title: "Descrizione",
        },
        {
            key: "inserted",
            title: "Inserito",
            children: [
                {
                    key: "insertedQuantity",
                    dataIndex: "insertedQuantity",
                    width: 100,
                    ellipsis: true,
                    align: "right",
                    title: "Quantità",
                },
                {
                    key: "insertedPrice",
                    dataIndex: "insertedPrice",
                    ellipsis: true,
                    width: 100,
                    align: "right",
                    title: "Importo",
                    render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
                },
            ],
        },
        {
            key: "invoiced",
            title: "Fatturato",
            children: [
                {
                    key: "invoicedQuantity",
                    dataIndex: "invoicedQuantity",
                    ellipsis: true,
                    width: 100,
                    align: "right",
                    title: "Quantità",
                },
                {
                    key: "invoicedPrice",
                    dataIndex: "invoicedPrice",
                    ellipsis: true,
                    align: "right",
                    width: 100,
                    title: "Importo",
                    render: (x) => x.toLocaleString("it-IT", { minimumFractionDigits: 2 }),
                },
            ],
        },
        {
            key: "delta",
            title: "Delta (Fatturato - inserito)",
            children: [
                {
                    key: "deltaQuantity",
                    dataIndex: "deltaQuantity",
                    ellipsis: true,
                    width: 100,
                    align: "right",
                    title: "Quantità",
                    render: (x) => (
                        <Typography.Text type={x < 0 ? "danger" : "success"} strong>
                            {x}
                        </Typography.Text>
                    ),
                },
                {
                    key: "deltaPrice",
                    dataIndex: "deltaPrice",
                    ellipsis: true,
                    align: "right",
                    width: 100,
                    title: "Importo",
                    render: (x) => (
                        <Typography.Text type={x < 0 ? "danger" : "success"} strong>
                            {x.toLocaleString("it-IT", { minimumFractionDigits: 2 })}
                        </Typography.Text>
                    ),
                },
            ],
        },
    ].filter((x) => !!x);

export default function ({ order }) {
    const [data, setData] = useState([]);
    const [sp, setSP] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (order) {
            const p = [...sp.entries()].reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
            form.setFieldsValue({
                ...p,
                includeMonth: /true/i.test(p.includeMonth),
                range: p.startDate && [dayjs(p.startDate), dayjs(p.endDate)],
            });
            setLoading(true);
            axiosInstance
                .get("delta", { params: { ...p, order } })
                .then(({ data }) => setData(data))
                .finally(() => setLoading(false));
        }
    }, [sp, order]);
    const includeMonth = /true/i.test(sp.get("includeMonth") ?? "false");
    const cols = columns(includeMonth);
    return (
        <>
            <Form
                layout="vertical"
                form={form}
                onFinish={(form) => {
                    if (form.range) {
                        form.startDate = form.range[0].startOf("month").format("YYYY-MM-DD");
                        form.endDate = form.range[1].endOf("month").format("YYYY-MM-DD");
                    }
                    delete form.range;
                    Object.entries(form).forEach(([k, v]) => sp.set(k, v));
                    setSP(sp, {replace: true});
                }}
            >
                <Row>
                    <Col flex={"auto"}>
                        <Space wrap>
                            <Form.Item
                                name={"range"}
                                label={"Competenza da/a"}
                                style={{ margin: 0 }}
                            >
                                <DatePicker.RangePicker picker="month" format={"MMMM YYYY"} />
                            </Form.Item>

                            <Form.Item
                                style={{ margin: 0 }}
                                name={"includeMonth"}
                                label="Altri filtri"
                                valuePropName="checked"
                            >
                                <Checkbox>Dividi righe per competenza</Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>
                    <Col flex={"none"}>
                        <Space wrap>
                            <Form.Item style={{ margin: 0 }} label=" ">
                                <Button
                                    onClick={() => {
                                        ["startDate", "endDate", "includeMonth"].forEach((x) =>
                                            sp.delete(x),
                                        );
                                        form.resetFields();
                                        setSP(sp,{replace: true});
                                    }}
                                >
                                    Reset filtri
                                </Button>
                            </Form.Item>
                            <Form.Item style={{ margin: 0 }} label=" ">
                                <Button type="primary" htmlType="submit">
                                    Applica filtri
                                </Button>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                loading={loading}
                dataSource={data.map((x) => ({ ...x, key: x.id })).sort((a, b) => a.id - b.id)}
                tableLayout="fixed"
                scroll={{ x: cols.reduce((acc, x) => acc + x.width, 0), y: 400 }}
                size="small"
                columns={cols}
                style={{ marginTop: 30 }}
                pagination={false}
                summary={(data) => {
                    const insertedAmount = data.reduce((acc, curr) => acc + curr.insertedPrice, 0);
                    const invoicedAmount = data.reduce((acc, curr) => acc + curr.invoicedPrice, 0);
                    const deltaAmount = data.reduce((acc, curr) => acc + curr.deltaPrice, 0);
                    return (
                        <Table.Summary fixed>
                            <Table.Summary.Row style={{ fontWeight: "bold" }}>
                                <Table.Summary.Cell colSpan={includeMonth ? 4 : 3} index={0}>
                                    Totale
                                </Table.Summary.Cell>
                                <Table.Summary.Cell align="right">
                                    {insertedAmount.toLocaleString("it-IT", {
                                        minimumFractionDigits: 2,
                                    })}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={2} align="right">
                                    {invoicedAmount.toLocaleString("it-IT", {
                                        minimumFractionDigits: 2,
                                    })}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={2} align="right">
                                    {deltaAmount.toLocaleString("it-IT", {
                                        minimumFractionDigits: 2,
                                    })}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    );
                }}
                bordered
            ></Table>
        </>
    );
}
