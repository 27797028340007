import { DatePicker, Form, Input, Modal, Select, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import axiosInstance from "../api";
import { filterOption, requiredField } from "../util";
import dayjs from "dayjs";

export default function ({ onOk, onCancel, data }) {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [users, setUsers] = useState([])
    const [form] = Form.useForm();
    const orderId = Form.useWatch("order", form);
    const order = orders.filter((x) => x.id === orderId)?.[0];

    useEffect(() => {
        if (data) {
            const initialFormValues = {
                date: dayjs(data?.date),
                manager: data?.manager.id,
                order: data?.order?.id,
                employees: data?.employees?.map((x) => x.id),
                vehicles: data?.vehicles?.map((x) => x.id),
                notes: data?.notes,
            };
            form.setFieldsValue(initialFormValues);
            axiosInstance.get("user").then(({ data }) => setUsers(data));
            axiosInstance
                .get("order", { params: { pageSize: 99999 } })
                .then(({ data }) => setOrders(data.data));
            axiosInstance
                .get("employee", { params: { date: data.date } })
                .then(({ data }) =>
                    setEmployees(data.map((x) => ({ value: x.id, label: x.fullName }))),
                );
            axiosInstance
                .get("vehicle", { params: { date: data.date } })
                .then(({ data }) =>
                    setVehicles(data.map((x) => ({ value: x.id, label: x.label + " " + x.name }))),
                );
        }
    }, [data]);

    const onFinish = (formData) => {
        setLoading(true);
        formData.date = formData.date.format("YYYY-MM-DD");
        axiosInstance
            .put(`allocation/${data.id}`, formData)
            .then(() => {
                onOk?.();
                form.resetFields();
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title="Modifica assegnazione"
            onCancel={() => {
                onCancel();
                form.resetFields();
            }}
            open={!!data}
            okButtonProps={{
                htmlType: "submit",
                form: "editResourceAllocationForm",
                loading: loading,
            }}
        >
            <Form id="editResourceAllocationForm" layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item label={"Data"} name={"date"} rules={[requiredField]}>
                    <DatePicker format={"dddd DD/MM/YYYY"} disabled />
                </Form.Item>
                <Form.Item name={"manager"} label="Responsabile" rules={[requiredField]}>
                    <Select
                        options={users.map((x) => ({ label: x.fullName, value: x.id }))}
                        filterOption={filterOption}
                        showSearch
                    />
                </Form.Item>
                <Form.Item label="Commessa" name={"order"} rules={[requiredField]}>
                    <Select
                        disabled
                        options={orders.map((x) => ({ value: x.id, label: x.title }))}
                        showSearch
                        filterOption={filterOption}
                    />
                </Form.Item>
                {order && (
                    <Typography>
                        <pre>
                            {order.customer.name}
                            <br />
                            {order.fullAddress}
                        </pre>
                    </Typography>
                )}
                <Form.Item label="Personale" name={"employees"} rules={[requiredField]}>
                    <Select
                        options={employees}
                        showSearch
                        filterOption={filterOption}
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item label="Mezzi" name={"vehicles"}>
                    <Select
                        options={vehicles}
                        showSearch
                        filterOption={filterOption}
                        mode="multiple"
                    />
                </Form.Item>
                <Form.Item label="Indicazioni per la squadra" name={"notes"}>
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
}
