import OrderAccountings from "./OrderAccountings";
import { createContext, useContext, useEffect, useState } from "react";
import axiosInstance from "../api";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    Avatar,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space,
    Spin,
    Tabs,
} from "antd";
import OrderAccountingReport from "./OrderAccountingReport";
import BackTitle from "../common/BackTitle";
import { EditOutlined } from "@ant-design/icons";
import OrderAccountingEmailHistory from "./OrderAccountingEmailHistory";
import OrderActivities from "./OrderActivities";
import { UserContext } from "../App";
import InvoicedAccountingItems from "../invoicedaccountingitem/InvoicedAccountingItems";
import OrderDeltas from "../delta/OrderDeltas";
import { filterOption, requiredField } from "../util";
import PermissionRequired from "../common/PermissionRequired";

export const OrderContext = createContext({});

const EditContractModal = ({ open, order, onCancel, onOk }) => {
    const [form] = Form.useForm();
    const [contracts, setContracts] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [multipliers, setMultipliers] = useState([]);
    const contractId = Form.useWatch("contractId", form);
    const contract = contracts.filter((x) => x.id === contractId)[0];
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (contract) {
            form.setFieldValue("multiplier", undefined);
            axiosInstance
                .get(
                    `customer/${order.contract.customer.id}/contract/${contractId}/multiplier/compact`,
                )
                .then(({ data }) => setMultipliers(data));
        }
    }, [contract]);

    useEffect(() => {
        if (open) {
            setDataLoading(true);
            axiosInstance
                .get(`customer/${order.contract.customer.id}/contract/compact`, {
                    params: { listing: order.contract.listing.id },
                })
                .then(({ data }) => setContracts(data))
                .finally(() => setDataLoading(false));
        }
    }, [open]);

    const onFinish = (formData) => {
        setLoading(true);
        axiosInstance
            .put(`order/${order.id}/contract`, formData)
            .then(() => {
                onOk();
                form.resetFields();
            })
            .then(() => {
                message.success("Contratto aggiornato");
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            onCancel={() => {
                form.resetFields();
                onCancel();
            }}
            okButtonProps={{ htmlType: "submit", form: "editOrderContractForm", loading }}
            title="Modifica contratto"
        >
            <Spin spinning={dataLoading}>
                <Form id="editOrderContractForm" form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item name={"contractId"} label="Contratto">
                        <Select
                            showSearch
                            filterOption={[filterOption]}
                            options={contracts.map((x) => ({ label: x.name, value: x.id }))}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"multiplier"}
                        label="Coefficiente di valorizzazione"
                        rules={contract?.requireMultiplier ? [requiredField] : []}
                    >
                        <Select
                            disabled={!contract?.requireMultiplier}
                            showSearch
                            filterOption={[filterOption]}
                            options={multipliers.map((x) => ({ label: x.name, value: x.id }))}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default function OrderDetails() {
    const { orderId } = useParams();
    const [order, setOrder] = useState({});
    const [loading, setLoading] = useState();
    const navigate = useNavigate();
    const [sp, setSP] = useSearchParams();
    const user = useContext(UserContext);
    const [editContractOpen, setEditContractOpen] = useState(false);

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get(`order/${orderId}`)
            .then(({ data }) => setOrder(data))
            .finally(() => setLoading(false));
    };
    useEffect(fetchData, []);

    return (
        <OrderContext.Provider value={order}>
            <EditContractModal
                open={editContractOpen}
                order={order}
                onOk={() => {
                    setEditContractOpen(false);
                    fetchData();
                }}
                onCancel={() => setEditContractOpen(false)}
            />
            <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                <Row>
                    <Col span={12}>
                        <BackTitle title={"Dettagli commessa"} padding={0} />
                    </Col>
                    <Col span={12}>
                        <Space
                            style={{ width: "100%", height: "100%", justifyContent: "end" }}
                            wrap
                            split={<Divider type="vertical" />}
                        >
                            <Button
                                type={"link"}
                                icon={<EditOutlined />}
                                onClick={() => navigate("edit")}
                            >
                                Modifica
                            </Button>
                        </Space>
                    </Col>
                </Row>
                <Spin spinning={loading}>
                    <Descriptions colon={3} layout={"vertical"} size={"small"}>
                        <Descriptions.Item label={"Nome"} span={3}>
                            {order.title}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Cliente"}>
                            {order.contract?.customer?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Contratto"}>
                            {order.contract?.name}
                            <PermissionRequired name={"order.updateContract"}>
                                <Button
                                    style={{ marginLeft: 8 }}
                                    type="link"
                                    size="small"
                                    onClick={() => setEditContractOpen(true)}
                                >
                                    Modifica
                                </Button>
                            </PermissionRequired>
                        </Descriptions.Item>
                        <Descriptions.Item label={"Coefficiente"}>
                            {order.multiplier?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Indirizzo"}>
                            {order.fullAddress}
                        </Descriptions.Item>
                        <Descriptions.Item label={"Creato da"}>
                            {order.createdBy?.firstName + " " + order.createdBy?.lastName}
                        </Descriptions.Item>
                    </Descriptions>
                </Spin>
                <Tabs
                    animated={false}
                    destroyInactiveTabPane
                    activeKey={parseInt(sp.get("tab") ?? "0")}
                    onChange={(key) => {
                        [...sp.keys()].forEach((x) => sp.delete(x));
                        sp.set("tab", key);
                        setSP(sp, { replace: true });
                    }}
                    items={[
                        {
                            key: 0,
                            label: "Attività",
                            children: <OrderActivities />,
                            permission: "activity.read",
                        },
                        {
                            key: 1,
                            label: "Fogli di contabilità",
                            children: (
                                <OrderAccountings customerId={order.contract?.customer?.id} />
                            ),
                            permission: "accounting.read",
                        },
                        {
                            key: 2,
                            label: "Dettaglio lavori",
                            children: <OrderAccountingReport />,
                            permission: "accounting.read",
                        },
                        {
                            key: 3,
                            label: "Storico invio email",
                            children: <OrderAccountingEmailHistory />,
                            permission: "accounting.readEmailHistory",
                        },
                        {
                            key: 4,
                            label: "Fatturato",
                            children: <InvoicedAccountingItems order={order.id} />,
                            //TODO: sistemare
                            permission: "invoicedAccountingItem.read",
                        },
                        {
                            key: 5,
                            label: "Delta",
                            children: <OrderDeltas order={order.id} />,
                            //TODO: sistemare
                            permission: "orderDelta.read",
                        },
                    ].filter((x) => user.permissions?.includes(x.permission))}
                ></Tabs>
            </div>
        </OrderContext.Provider>
    );
}
