import { Tabs } from "antd";
import BackTitle from "../common/BackTitle";
import EmployeeMansions from "./mansion/EmployeeMansions";

export default function () {
    return (
        <>
            <BackTitle backEnabled={false} padding={0} title="Impostazioni"/>
            <Tabs
                items={[
                    {
                        key: "1",
                        label: "Mansioni",
                        children: <EmployeeMansions />,
                    },
                ]}
            />
        </>
    );
}
